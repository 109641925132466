<template>
  <!-- 站点管理 -->
  <div class="siteManage">
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">站点代码：</div>
        <div class="col-2">
          <el-input
            v-model="queryForm.code"
            clearable
            placeholder="请输入站点代码"
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">站点名称：</div>
        <div class="col-2 col-2-a">
          <el-input
            v-model="queryForm.name"
            clearable
            placeholder="请输入站点名称"
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">拼音缩写：</div>
        <div class="col-2 col-2-a">
          <el-input
            v-model="queryForm.pinyinInitials"
            clearable
            placeholder="请输入拼音缩写"
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">类型：</div>
        <div class="col-2 col-2-a">
          <el-select v-model="queryForm.type" placeholder="请选择" clearable>
            <el-option
              v-for="item in siteTypeList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">数据来源：</div>
        <div class="col-2">
          <el-select v-model="queryForm.source" placeholder="请选择" clearable>
            <el-option
              v-for="item in dataSourceList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box" style="width: 36%">
        <el-button type="primary" size="small" @click="queryFun"
          >查询</el-button
        >
        <el-button type="primary" size="small" @click="exportFun"
          >导出</el-button
        >
        <el-button type="primary" size="small" @click="newFun">新增</el-button>
      </div>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :operation="true"
    >
      <template slot-scope="scope">
        <el-button
          type="text"
          size="small"
          sort="primary"
          @click="updateFun(scope.scopeRow)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="small"
          sort="primary"
          @click="deleteFun(scope.scopeRow)"
          >删除</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.currentPage"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <charterPopup
      v-if="show"
      width="1000"
      :header-text="headerText"
      :disabled="disabled"
      @closePopupFun="closePopupFun"
      @confirmFun="confirmFun"
    >
      <div class="contentPopup">
        <div class="left">
          <el-form
            ref="Form"
            :model="pointForm"
            :rules="rules"
            label-width="140px"
            label-position="right"
            class="demo-form dialog-form"
          >
            <el-form-item label="站点代码：" prop="code">
              <el-input
                v-model="pointForm.code"
                clearable
                maxlength="15"
                placeholder="请输入站点代码"
              ></el-input>
            </el-form-item>
            <el-form-item label="站点名称：" prop="name">
              <el-input
                v-model="pointForm.name"
                clearable
                maxlength="20"
                placeholder="请输入站点名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="拼音缩写：" prop="pinyinInitials">
              <el-input
                v-model="pointForm.pinyinInitials"
                clearable
                maxlength="20"
                placeholder="请输入拼音缩写"
              ></el-input>
            </el-form-item>
            <el-form-item label="类型：" prop="type">
              <el-select
                v-model="pointForm.type"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in siteTypeList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据来源：" prop="source">
              <el-input
                v-model="pointForm.source"
                disabled="disabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="详细地址：">
              <el-input
                v-model="pointForm.detailAddress"
                disabled="disabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="启用：">
              <el-switch
                v-model="pointForm.status"
                disabled="disabled"
                active-color="#13ce66"
                inactive-color="#E3E3E3"
                @click.stop.native
              ></el-switch>
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <div v-show="disabled" class="mapMask"></div>
          <div v-show="!disabled" class="searchBox">
            <i class="iconfont its_gy_sousuo"></i>
            <el-input
              id="tipinput"
              v-model="searchInput"
              type="text"
              clearable
              placeholder="输入搜索地址"
            ></el-input>
            <div class="searchContent">
              <div
                v-for="(item, index) in searchList"
                :key="index"
                class="content-address"
                @click="searchClick(item)"
              >
                <span class="address">{{ item.name }}</span>
                <span class="addressDetail"
                  >{{ `${item.pname}${item.cityname}${item.address}` }}
                </span>
              </div>
            </div>
          </div>
          <div id="map" class="map"></div>
        </div>
      </div>
    </charterPopup>
  </div>
</template>

<script>
import {
  addSiteAPI,
  siteDataRenderAPI,
  updateSiteAPI,
  deleteSiteAPI,
  exportSiteAPI,
} from "@/api/lib/customizedBus.js";
export default {
  data() {
    return {
      siteTypeList: [
        { label: "车站", id: 0 },
        { label: "停靠站", id: 1 },
      ],
      dataSourceList: [
        { label: "平台", id: 0 },
        { label: "票务系统", id: 1 },
      ],
      map: null,
      searchList: [],
      searchInput: "",
      rules: {
        code: [{ required: true, message: "请输入站点代码", trigger: "blur" }],
        name: [{ required: true, message: "请输入站点名称", trigger: "blur" }],
        pinyinInitials: [
          { required: true, message: "请输入拼音缩写", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        source: [
          { required: true, message: "请选择数据来源", trigger: "change" },
        ],
      },
      disabled: false,
      headerText: "",
      show: false,
      total: 0,
      tableData: [],
      titleName: [
        {
          title: "站点代码",
          props: "code",
        },
        {
          title: "站点名称",
          props: "name",
        },
        {
          title: "拼音缩写",
          props: "pinyinInitials",
        },
        {
          title: "类型",
          props: "type",
          SpecialJudgment: (res) => {
            return res === 0 ? "车站" : "停靠站";
          },
        },
        {
          title: "数据来源",
          props: "source",
          SpecialJudgment: (res) => {
            return res === 0 ? "平台" : "票务系统";
          },
        },
        {
          title: "详细地址",
          props: "detailAddress",
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
                disabled: "disabled",
              },
              on: {
                change: (val) => {
                  let obj = {
                    ...params,
                    status: params.status === 0 ? 1 : 0,
                  };
                  this.renderDataUpdateFun(obj);
                },
              },
            });
          },
        },
      ],
      pointForm: {
        code: "", //站点代码
        name: "", //站点名称
        pinyinInitials: "", //拼音缩写
        type: "", //类型
        source: "", //数据来源
        detailAddress: "",
        latitude: "",
        longitude: "",
        status: false,
      },
      queryForm: {
        pageSize: 10,
        currentPage: 1,
        code: "", //站点代码
        name: "", //站点名称
        pinyinInitials: "", //拼音缩写
        type: "", //类型
        source: "", //数据来源
      },
      exportForm: {},
      marker: null,
    };
  },
  watch: {
    searchInput: {
      handler(val) {
        if (val) this.searchFun(val);
      },
    },
  },
  mounted() {
    this.renderData();
  },
  methods: {
    deleteFun({ id }) {
      this.$confirm("确认删除吗？", "提示")
        .then(() => {
          deleteSiteAPI({ id: id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.renderData();
            }
          });
        })
        .catch(() => {});
    },
    searchClick(item) {
      this.map.setCenter([item.location.lng, item.location.lat]);
      this.map.setZoom(16);
      this.pointForm.detailAddress = item.address;
      this.pointForm.latitude = item.location.lat;
      this.pointForm.longitude = item.location.lng;
      this.searchList = [];
      this.searchInput = "";
    },
    searchFun(val) {
      let vm = this;
      AMap.plugin("AMap.PlaceSearch", function () {
        var autoOptions = {
          city: "四川",
          extensions: "all",
          citylimit: true, //是否强制在设置的城市内搜索
          autoFitView: true,
          children: 1,
        };
        var placeSearch = new AMap.PlaceSearch(autoOptions);
        placeSearch.search(val, function (status, result) {
          // 搜索成功时，result即是对应的匹配数据
          if (result.info == "OK") {
            vm.searchList = result.poiList.pois;
          }
        });
      });
    },
    // 实例化地图
    initMap() {
      this.map = null;
      this.map = new AMap.Map("map", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      this.positionPicker();
    },
    // 拖拽选址方法
    positionPicker() {
      let vm = this;
      // 地图拖动的时候清空文本标记Marker Text
      this.map.on("dragging", function () {
        vm.map.clearMap();
      });
      this.map.on("zoomchange", function () {
        vm.map.clearMap();
      });
      // 地图拖拽
      AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
        if (vm.pickerPosition) {
          vm.pickerPosition = null;
        }
        vm.pickerPosition = new PositionPicker({
          mode: "dragMap",
          map: vm.map,
          zIndex: 666,
          iconStyle: {
            //自定义外观
            url: require("@/assets/images/home/from_icon2.png"),
            ancher: [15, 30],
            size: [30, 39],
          },

          zIndex: 666,
          // iconStyle: {
          //   //自定义外观
          //   url: require("@/assets/images/home/from_icon2.png"),
          //   ancher: [15, 30],
          //   size: [30, 39]
          // }
        });
        vm.pickerPosition.on("success", function (positionResult) {
          if (positionResult.regeocode.pois.length > 0) {
            vm.pointForm.detailAddress = positionResult.address;
            vm.pointForm.latitude = positionResult.position.lat;
            vm.pointForm.longitude = positionResult.position.lng;
          }
          vm.map.clearMap();
          vm.marker = new AMap.Text({
            text: positionResult.address,
            zIndex: 777,
            anchor: "center",
            offset: new AMap.Pixel(0, -60),
            style: {
              "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
              padding: "14px",
            },
            position: [
              positionResult.position.lng,
              positionResult.position.lat,
            ],
          });
          vm.marker.setMap(vm.map);
        });
        vm.pickerPosition.start();
        vm.map.panBy(0, 1);
      });
    },
    // 表格数据渲染
    renderData() {
      siteDataRenderAPI(this.queryForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 点击编辑按钮
    updateFun({
      id,
      code, //站点代码
      name, //站点名称
      pinyinInitials, //拼音缩写
      type, //类型
      source, //数据来源
      detailAddress,
      latitude,
      longitude,
      status,
    }) {
      this.show = true;
      this.headerText = "编辑";
      this.pointForm = {
        id,
        code, //站点代码
        name, //站点名称
        pinyinInitials, //拼音缩写
        type, //类型
        source: source === 0 ? "平台" : "票务系统", //数据来源
        detailAddress,
        latitude,
        longitude,
        status: status === 0 ? true : false,
      };
      this.$nextTick(() => {
        this.initMap();
        if (longitude) {
          this.map.setCenter([longitude, latitude]);
        }
      });
    },
    renderDataUpdateFun(obj) {
      updateSiteAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("修改成功");
          this.renderData();
          this.closePopupFun();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 弹窗确定按钮返回事件
    confirmFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          let obj = this.deepClone(this.pointForm);
          if (obj.status == true) {
            obj.status = 0;
          } else {
            obj.status = 1;
          }
          if (this.headerText == "新增") {
            obj.source = 0;
            addSiteAPI(obj).then((res) => {
              console.log(res);
              if (res.code == "SUCCESS") {
                this.$message.success("新增成功");
                this.closePopupFun();
                this.renderData();
              } else {
                this.$message.error(res.msg);
              }
            });
          } else if (this.headerText == "编辑") {
            obj.source = obj.source == "平台" ? 0 : 1;
            this.renderDataUpdateFun(obj);
          }
        }
      });
    },
    // 关闭弹窗事件
    closePopupFun() {
      this.show = false;
      this.disabled = false;
      this.pointForm = {
        code: "", //站点代码
        name: "", //站点名称
        pinyinInitials: "", //拼音缩写
        type: "", //类型
        source: "", //数据来源
        detailAddress: "",
        latitude: "",
        longitude: "",
        status: false,
      };
    },
    // 点击导出
    exportFun() {
      exportSiteAPI(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "定制班线站点表" + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 点击查询
    queryFun() {
      this.exportForm = this.deepClone(this.queryForm);
      this.renderData();
    },
    // 点击新增
    newFun() {
      this.show = true;
      this.headerText = "新增";
      this.pointForm.source = "平台";
      this.pointForm.status = true;
      this.$nextTick(() => {
        this.initMap();
      });
    },
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.renderData();
    },
  },
};
</script>

<style scoped lang="scss">
.contentPopup {
  display: flex;
  .dialog-form {
    padding: 0;
  }
  .left {
    width: 40%;
  }
  .right {
    width: 60%;
    padding-right: 20px;
    position: relative;
    .map {
      width: 100%;
      height: 418px;
    }
  }
}
.mapMask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.searchBox {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 230px !important;
  height: 28px !important;
  z-index: 2056;
  /deep/.el-input {
    input {
      padding-left: 30px;
    }
  }
  i {
    position: absolute;
    font-size: 20px;
    z-index: 2057;
    top: 4px;
    left: 6px;
  }
  .searchContent {
    max-height: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #ffffff;
  }
  .address {
    width: calc(100% - 8px);
    display: inline-block;
    cursor: pointer;
    padding: 4px 4px 0 4px;
    line-height: 24px;
  }
  .addressDetail {
    width: calc(100% - 8px);
    display: inline-block;
    border-bottom: 1px solid #f4f4f4;
    cursor: pointer;
    padding: 0 4px 4px 4px;
    line-height: 24px;
    font-size: 12px;
    color: #afafaf;
  }
}
.siteManage {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  background: #ffffff;
  padding: 16px 20px;
  .ActionBar {
    background: #f7f8f9;
    padding: 16px 0 0 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    height: 114px;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box {
      width: calc(25% - 18px);
      height: 32px;
      line-height: 32px;
      margin-right: 7px;
      margin-bottom: 16px;
      padding-right: 10px;
      .col-1-a {
        width: 90px !important;
      }
      .col-1 {
        width: 90px;
        display: inline-block;
      }
      .col-2-a {
        width: calc(100% - 110px) !important;
      }
      .col-2 {
        display: inline-block;
        width: calc(100% - 110px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
  .showDetials {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .showParams {
      width: 345px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .paramsItem {
        width: 115px;
        height: 70px;
        .items {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 15px;
        }
      }
      p {
        text-align: center;
        box-sizing: border-box;
        font-size: 14px;
      }
      .paramsItem:nth-child(1) {
        border-bottom: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(2) {
        border-bottom: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(3) {
        border-bottom: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(4) {
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(5) {
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
    }
  }
}
</style>
